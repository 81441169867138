import axiosInstance from "../axiosInstance";

export const addLicense  = async (formData: FormData) => {
  const response = await axiosInstance.post("/api/create_license", formData);
  return response.data;
};



export interface LicenseResponse {
  error?: string;
  licenseId?: string;
  expirationDate?: string;
}
export const getLicense  = async (user_id:string): Promise<LicenseResponse> => {
  const response = await axiosInstance.get(`/get_license?user_id=${user_id}`);
  return {
    error: response.data.error, 
    licenseId: response.data.licenca_id,
    expirationDate: response.data.data_expiracao,
  };
};



export interface RemoveLicenseResponse {
  error?: string;
  success?: string;
}
export const removeLicense  = async (user_id:string, license_id:string): Promise<RemoveLicenseResponse> => {
  const formData = new FormData();
  formData.append("user_id",user_id);
  formData.append("license_id",license_id);  
  const response = await axiosInstance.postForm(`/remove_license`,formData);
  return response.data;
};



export interface SetUserLicenseResponse extends RemoveLicenseResponse{}
export const setLicense  = async (formData: FormData): Promise<SetUserLicenseResponse> => {
  const response = await axiosInstance.postForm("/set_license", formData);
  return response.data;
};
