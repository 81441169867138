import React, { useEffect, useMemo } from "react";
import AppRoutes from "./routes/Routes";
import Navbar from "./components/navbar/Navbar";
import GlobalStyles from "./GlobalStyles";
import { useAuth } from "./hooks/useAuth";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react"
import { useLocation, useNavigate } from "react-router-dom";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const App: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const privatePage = useMemo(() => {
    const pages: string[] = [
      "/changePassword",
      "/dashboard",
      "/financeiro",
      "/estoque",
      "/servicos",
      "/createLicense",
      "/clientes",
    ];
    const actualLocation = location.pathname;

    return pages.includes(actualLocation);
  }, [location]);

  useEffect(() => {
    if (user && !privatePage) {
      navigate("/dashboard");
    }
  }, [user, privatePage, navigate]);

  return (
    <AppWrapper>
      {user && <Navbar />}
      <GlobalStyles />
      <Analytics />
      <AppRoutes />
    </AppWrapper>
  );
};

export default App;
