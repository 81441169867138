import React, { useState } from "react";
import {
  Container,
  FormContainer,
  TextDanger,
  ImageFieldWrapper,
  InputImage,
  InputGroupWrapper,
  ButtonStyled,
} from "./styled";
import { Form, InputGroup } from "react-bootstrap";
import { changePassword } from "../../api/change-password";
import { useAuth } from "../../hooks/useAuth";
import { useToastMessages } from "../../hooks/useToastMessages";


import openEyes from "../../images/icons/eyes/olho_aberto.png";
import closedEyes from "../../images/icons/eyes/olho_fechado.png";


const ChangePassword: React.FC = () => {
  const {addMessage} = useToastMessages();
  const {user} = useAuth();
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    if(formData.get('confirm_password') !== formData.get('new_password')){
      addMessage({ category: "error", message: "As senhas não conferem" });
      return;
    }

    formData.append("user_id", user!.user_id);
    try {
      const response = await changePassword(formData);
      if(response.success){
        addMessage({ category: "success", message: "Senha redefinida com sucesso!" });
        form.reset();
      }else{
        addMessage({ category: "error", title: "Erro ao redefinir a senha", message: `${response.error}` });
      }
    } catch (error) {
      addMessage({ category: "error", message: `Ocorreu um erro ao redefinir a senha` });
    }
  };

  return (
    <Container className="change-password-container specific-change-password-style">
      <h1 className="text-center my-4">Redefinir Senha</h1>
      <FormContainer onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="current_password_control">
          <Form.Label>Senha Atual: <TextDanger>*</TextDanger></Form.Label>
          <InputGroup>
            <InputGroupWrapper
              type={showCurrentPassword ? "text" : "password"}
              name="current_password"
              required
            />
            <ImageFieldWrapper onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
              <InputImage
                src={showCurrentPassword ? openEyes : closedEyes}
                alt={showCurrentPassword ? "Ocultar Senha" : "Mostrar Senha"}
              />
            </ImageFieldWrapper>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="new_password_control">
          <Form.Label>Nova Senha: <TextDanger>*</TextDanger></Form.Label>
          <InputGroup>
            <InputGroupWrapper
              type={showNewPassword ? "text" : "password"}
              name="new_password"
              required
            />
            <ImageFieldWrapper onClick={() => setShowNewPassword(!showNewPassword)}>
              <InputImage
                src={showNewPassword ? openEyes : closedEyes}
                alt={showNewPassword ? "Ocultar Senha" : "Mostrar Senha"}
              />
            </ImageFieldWrapper>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirm_password_control">
          <Form.Label> Confirmar Nova Senha: <TextDanger>*</TextDanger></Form.Label>
          <InputGroup>
            <InputGroupWrapper
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              required
            />
            <ImageFieldWrapper onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              <InputImage
                src={showConfirmPassword ? openEyes : closedEyes}
                alt={showConfirmPassword ? "Ocultar Senha" : "Mostrar Senha"}
              />
            </ImageFieldWrapper>
          </InputGroup>
        </Form.Group>
        <ButtonStyled type="submit">Redefinir Senha</ButtonStyled>
      </FormContainer>
    </Container>
  );
};

export default ChangePassword;
