export const theme = {
  bootstrap: {
    primary: "#007bff",
    secondary: "#6c757d",
    success: "#28a745",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#17a2b8",
    light: "#f8f9fa",
    dark: "#343a40",
    white: "#ffffff",
    black: "#000000",
  },
  theme: {
    colors: {
      primary: "#feeb9c",
      text: "#333333",
      background: "#FFFFFF",
      foreground: "#f8f9fa",
    },
    button: {
      primary: "#000000",
      secondary: "#5ca1c8",
      text: "#FFFFFF",
      danger: "#e74c3c",
      hover: "#333",
    },
    dialog: {
      success: "#dffe9c",
      warning: "#fed79c",
    },
  },
};
