import { Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
`;

export const FormContainer = styled(Form)`
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
`;

export const FormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
`;

export const InputGroupWrapper = styled(Form.Control)`
  padding: 12px;
  border-right: 0px;
`;

export const ImageFieldWrapper = styled(InputGroup.Text)`
  border-left: 0px;
  background: none;
  cursor: pointer;
`;

export const InputImage = styled.img`
  width: 20px;
`;

export const ButtonStyled = styled(Button)`
  display: inline-block;
  padding: 12px 0;
  background-color: ${({ theme }) => theme.theme.button.primary};
  color: ${({ theme }) => theme.theme.button.text};
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 4px;
  border-color: #000;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.theme.button.hover};
    border-color: ${({ theme }) => theme.theme.button.hover};
  }
`;

export const TextDanger = styled.span`
  color: red;
`;
